import JoditEditor from "jodit-react";

function HTMLInput({ title, value, onChange }) {
  return (
    <div className="my-3">
      <h1 className="mb-2">{title}</h1>
      <JoditEditor value={value} onChange={onChange} />
    </div>
  );
}
export default HTMLInput;
