import { Row } from "../../Tools/Grid-system";
import DeleteParamsAll from "./DeleteParamsAll";
import FilerBtn from "./FilerBtn";

function ActiveFilter() {
  return (
    <Row>
      <DeleteParamsAll />
      <FilerBtn name="status" title="طلبات بالإنتظار" value="WAITING" />
      <FilerBtn name="status" title="الطلبات المكتملة" value="COMPLETED" />
      <FilerBtn name="status" title="الطلبات المحظورة" value="FORBIDDEN" />
      <FilerBtn name="status" title="الطلبات الملغية" value="CANCELED" />
      {/* <FilerBtn name="is_active" title="البائعين الفعالين" value="1" /> */}
    </Row>
  );
}
export default ActiveFilter;
