import { BsWhatsapp } from "react-icons/bs";
import { FaFacebook, FaInstagram, FaPhone } from "react-icons/fa";
import { MdOutlineMail, MdWorkOutline } from "react-icons/md";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { ButtonRed, CardBox, Data, Loading, Title } from "../../components";
const ContactUs = () => {
  const { data, isLoading } = useFETCH(`contact-us`);
  let dataAll = data?.data.data;
  let ContactUs = [
    {
      icon: FaPhone,
      color: "#FEDADA",
      name: dataAll?.phone_number,
    },
    {
      icon: MdOutlineMail,
      color: "#9F7CC6",
      name: dataAll?.email,
    },
    {
      icon: MdWorkOutline,
      color: "#5e2aC6",
      name: dataAll?.working_hours,
    },
    {
      icon: FaInstagram,
      color: "#CB3878",
      name: dataAll?.instagram,
    },
    {
      icon: FaFacebook,
      color: "#3B5998",
      name: dataAll?.facebook,
    },
    {
      icon: BsWhatsapp,
      color: "#1DCD2A",
      name: dataAll?.whatsapp,
    },
  ];
  return (
    <div>
      <Title title="تواصل معنا" />
      {isLoading ? <Loading /> : ""}
      <Row gap={5} justify="center">
        {ContactUs.map((e, i) => (
          <Col
            key={i}
            md={5}
            className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
          >
            <e.icon size={50} color={e.color} />
            <p className="font-semibold px-2 text-Gray text-xl">{e.name}</p>
          </Col>
        ))}
      </Row>
      <div className="fixed bottom-10 left-10">
        <ButtonRed link="/Contact-Us/edit" name="تعديل" className="px-7 py-2" />
      </div>
    </div>
  );
};

export default ContactUs;
