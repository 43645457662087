import { useLocation } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Add, Table, Title, TopCompoundFilter } from "../../components";

function Launches() {
  const { search } = useLocation({});
  const { data, isLoading, deleteItem } = useFETCH(
    `coming-soon-items${search}`,
    "coming-soon-items"
  );
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      image: fileUrl + e.image,
      name_ar: e.name?.ar,
      name_en: e.name?.en,
      price_start_from: e.price_start_from,
    };
  });
  return (
    <div>
      <Title title="قادم قريباً" />
      <TopCompoundFilter />
      <Table
        isLoading={isLoading}
        thead={[
          "الرقم",
          "الصورة",
          "الاسم بالعربي",
          "الاسم بالإنكليزي",
          "السعر يبدأ من",
        ]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={dataAll}
        tbody={["id", "image", "name_ar", "name_en", "price_start_from"]}
        funDelete={deleteItem}
        edit="launches"
      />
      <Add link="/launches/add" />
    </div>
  );
}
export default Launches;
