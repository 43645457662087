import { Col, Row } from "../../Tools/Grid-system";
import Search from "../Input/Search";
import FilerBtn from "./FilerBtn";
function CoinFilter() {
  return (
    <div>
      <Row>
        <Col md={4} xs={6}>
          <Search />
        </Col>
      </Row>
      <Row className="my-5">
        <FilerBtn name="is_active" title="متاح" value="1" />
        <FilerBtn name="is_active" title="غير متاح" value="0" />
      </Row>
    </div>
  );
}
export default CoinFilter;
