function Selected({ children, text, title, name, value, onChange, className }) {
  return (
    <div className="">
      {text && <h1 className={`text-start mb-2`}>{text}</h1>}
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={`w-full border border-[#cccccc] py-[6px] rounded-md text-lg outline-none text-center ${className}`}
      >
        <option value="" disabled selected hidden className="!text-[#8697BA]">
          {title}
        </option>
        {children}
      </select>
    </div>
  );
}
export default Selected;
