import { BiMenu } from "react-icons/bi";
import { useContextHook } from "../../Context/ContextOPen";
import { Popup, Search } from "../../components";
import ImageList from "./ImageList";
import { Link } from "react-router-dom";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { logout } from "../../Tools/APIs/useMyAPI";
import Logo from "../../images/Logo.png";
const Navbar = () => {
  const { changeMenu } = useContextHook();
  return (
    <>
      <div className="sticky lg:mx-12 top-0 z-20 flex items-center max-sm:flex-wrap max-sm:gap-3 bg-white justify-between px-8 py-2 border-b-2 border-Main">
        <BiMenu
          size={40}
          className="cursor-pointer text-Main "
          onClick={changeMenu}
        />
        {/* <div className="max-sm:order-3">
          <Search />
        </div> */}
        {/* <div className="max-sm:order-1">
          <ImageList />
        </div> */}
        <Link to="/">
          <img src={Logo} alt="" className="object-contain w-28 h-14" />
        </Link>
        <Popup
          id={`logout`}
          text="تسجيل خروج"
          description="هل انت متأكد من تسجيل الخروج ؟"
          onClick={() => logout("admin/logout")}
        >
          <RiLogoutBoxRLine className="cursor-pointer text-Main" size={30} />
        </Popup>
      </div>
    </>
  );
};

export default Navbar;
