import { useLocation } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Add, PropertyTypeFilter, Table, Title } from "../../components";
const PropertyType = () => {
  const { search } = useLocation({});
  const { data, isLoading, deleteItem } = useFETCH(
    `property-type${search}`,
    "property-type"
  );
  const dataAll = data?.data?.data?.map((e) => {
    return {
      id: e.id,
      image: fileUrl + e.icon,
      name_ar: e.name?.ar,
      name_en: e.name?.en,
      type: e.type === "COMMERCIAL" ? "تجاري" : "سكني",
    };
  });
  return (
    <>
      <Title title="أنواع العقارات" />
      <PropertyTypeFilter />
      <Table
        isLoading={isLoading}
        thead={[
          "الرقم",
          "أيقونة العقار",
          "الاسم بالعربي",
          "الاسم بالإنكليزي",
          "النوع",
        ]}
        tData={dataAll}
        tbody={["id", "image", "name_ar", "name_en", "type"]}
        funDelete={deleteItem}
        edit="property-type"
      />
      <Add link="/property-type/add" />
    </>
  );
};

export default PropertyType;
