import { usePOST } from "../../Tools/APIs/useMyAPI";
import Popup from "../Popup/Popup";
const IsActive = ({ id, active, children, name }) => {
  const { handleSubmit } = usePOST({});
  const handleClick = () => {
    handleSubmit(`coins/${id}?is_active=${active}`, "", "", true);
  };
  return (
    <>
      <Popup
        text={`جعل العملة ${name || ""} ` + "متاحة"}
        id={name || "قبول"}
        description={`هل تريد جعل العملة ${name || ""} متاحة`}
        onClick={handleClick}
      >
        {children}
      </Popup>
    </>
  );
};

export default IsActive;
