import { usePOST } from "../../Tools/APIs/useMyAPI";
import Popup from "../Popup/Popup";
const SellerActive = ({ id, active, children, name }) => {
  const { handleSubmit } = usePOST({});
  const handleClick = () => {
    handleSubmit(`sellers/${id}?status=${active}`, "", "", true);
  };
  return (
    <>
      <Popup
        text={` ${name || "قبول"} ` + "الطلب"}
        id={name || "قبول"}
        description={`هل تريد ${name} هذا الطلب`}
        onClick={handleClick}
      >
        {children}
      </Popup>
    </>
  );
};

export default SellerActive;
