import parse from "html-react-parser";
import Popup from "../Popup/Popup";

function PopupTable({ text, description }) {
  return (
    <div>
      <Popup
        hide={true}
        hover={true}
        description={parse("" + text)}
        className="min-w-[200px]"
        desClass="text-lg"
        closeP={true}
        title={description}
      >
        <div className="text-sm cursor-pointer">
          <p className="w-40 mx-auto h-5 overflow-hidden">{parse("" + text)}</p>
          <p className="text-center text-[12px] text-Secondary">إقرأ المزيد</p>
        </div>
      </Popup>
    </div>
  );
}
export default PopupTable;
