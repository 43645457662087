import { Row } from "../../Tools/Grid-system";
import DeleteParamsAll from "./DeleteParamsAll";
import FilerBtn from "./FilerBtn";

function TopCompoundFilter() {
  return (
    <div>
      <Row className="my-5">
        <DeleteParamsAll />
        <FilerBtn name="trending" title="المناطق الرائجة" value="1" />
        <FilerBtn name="trending" title="المناطق الغير رائجة" value="0" />
      </Row>
    </div>
  );
}
export default TopCompoundFilter;
