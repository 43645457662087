import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Add, PopupTable, Table, Title } from "../../components";
const Founders = ({ name, api, rout }) => {
  const { data, isLoading, deleteItem } = useFETCH(
    `founders?type=${api}`,
    "founders"
  );
  const dataAll = data?.data?.data?.map((e) => {
    return {
      id: e.id,
      image: fileUrl + e.image,
      name_ar: e.name?.ar,
      name_en: e.name?.en,
      description_ar: <PopupTable description="الوصف بالعربي" text={e?.description?.ar} />,
      description_en: <PopupTable description="الوصف بالإنكليزي" text={e?.description?.en} />,
    };
  });
  return (
    <>
      <Title title={name} />
      <Table
        isLoading={isLoading}
        thead={[
          "الرقم",
          "أيقونة المرفق",
          "الاسم بالعربي",
          "الاسم بالإنكليزي",
          "الوصف بالعربي",
          "الوصف بالإنكليزي",
        ]}
        tData={dataAll}
        tbody={[
          "id",
          "image",
          "name_ar",
          "name_en",
          "description_ar",
          "description_en",
        ]}
        funDelete={deleteItem}
        edit={rout}
      />
      <Add link={`/${rout}/add`} />
    </>
  );
};

export default Founders;
