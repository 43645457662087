import React from "react";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import { CardBox, Data, Loading, Title } from "../../components";

const Message = () => {
  const { data, isLoading, deleteItem } = useFETCH(`messages`, "messages");
  return (
    <>
      <Title title="الرسائل" />
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data.data?.total / data?.data.data.per_page)}
      >
        <Row>
          {data?.data.data.data?.map((e) => (
            <Col lg={4} md={6} key={e.id}>
              <CardBox showEdit={true} deleteClick={() => deleteItem(e)}>
                <Data name="الاسم" text={e.name} />
                <Data name="الموقع المفضل" text={e.preferred_location} />
                <Data name="الموقع" text={e.location} />
                <Data name="رقم الهاتف" text={e.phone_number} />
                <Data name="الرسائل" text={e.description} />
              </CardBox>
            </Col>
          ))}
        </Row>
      </Pagination>
    </>
  );
};

export default Message;
