import { useParams } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Data } from "../../components";

function CompoundsDetails() {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`compounds/${id}?local=none`);
  const dataAll = data?.data.data?.compound;
  console.log(dataAll);
  return (
    <div>
      <Container>
        <Row className="border-2 border-Main rounded-xl p-4">
          <Row justify="center">
            <h1 className="text-Main font-bold mb-4">صور المجمع :</h1>
            {dataAll?.images.map((e) => (
              <Col lg={3} md={4} sm={6}>
                <img
                  src={fileUrl + e?.file}
                  alt=""
                  className="w-full h-[170px] rounded-2xl p-2"
                />
              </Col>
            ))}
          </Row>
          <Col md={6}>
            <Data
              load={isLoading}
              name="الاسم بالإنكليزي"
              text={dataAll?.name?.en}
            />
          </Col>
          <Col md={6}>
            <Data
              load={isLoading}
              name="الاسم بالعربي"
              text={dataAll?.name?.ar}
            />
          </Col>
          <Col md={6}>
            <Data
              load={isLoading}
              name="الموقع بالإنكليزي"
              text={dataAll?.address?.en}
            />
          </Col>
          <Col md={6}>
            <Data
              load={isLoading}
              name="الموقع بالعربي"
              text={dataAll?.address?.ar}
            />
          </Col>
          <Col md={6}>
            <Data
              load={isLoading}
              name="عمليات الدفع بالإنكليزي"
              text={dataAll?.payment_plans?.en}
            />
          </Col>
          <Col md={6}>
            <Data
              load={isLoading}
              name="عمليات الدفع بالعربي"
              text={dataAll?.payment_plans?.ar}
            />
          </Col>
          <Col lg={3} md={6}>
            <Data
              load={isLoading}
              name="السعر الأولي"
              text={dataAll?.start_price}
            />
          </Col>
          <Col lg={3} md={6}>
            <Data
              load={isLoading}
              name="السعر النهائي"
              text={dataAll?.end_price}
            />
          </Col>
          <Col lg={3} md={6}>
            <Data
              load={isLoading}
              name="رقم الهاتف"
              text={dataAll?.phone_number}
            />
          </Col>
          <Col lg={3} md={6}>
            <Data
              load={isLoading}
              name="رقم الواتس أب"
              text={dataAll?.whatsapp}
            />
          </Col>
          <Data
            load={isLoading}
            name="الوصف بالعربي"
            text={dataAll?.description?.ar}
          />
          <Data
            load={isLoading}
            name="الوصف بالإنكليزي"
            text={dataAll?.description?.en}
          />
          <h1 className="text-Main font-bold my-4">صورة موقع المجمع :</h1>
          <img
            src={fileUrl + dataAll?.image_location}
            alt=""
            className="w-[200px] h-[200px] object-contain rounded-2xl p-2"
          />
        </Row>
      </Container>
    </div>
  );
}
export default CompoundsDetails;
