import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { PopupTable, Table, Title } from "../../components";
function TextInfo() {
  const { data } = useFETCH(`info-text/description_contact`);
  const { data: data1 } = useFETCH(`info-text/acceptable_use`);
  const { data: data2 } = useFETCH(`info-text/automated_queries`);
  const { data: data3 } = useFETCH(`info-text/description_departments`);
  const { data: data4 } = useFETCH(`info-text/description_launching`);
  const { data: data5, isLoading: load } = useFETCH(`info-text/address`);
  const { data: data6 } = useFETCH(`info-text/terms_and_conditions`);
  const { data: data7 } = useFETCH(`info-text/our_products`);
  const { data: data8 } = useFETCH(`info-text/title_home`);
  const { data: data9 } = useFETCH(`info-text/design`);
  const { data: data10 } = useFETCH(`info-text/description_buyer`);
  const { data: data11 } = useFETCH(`info-text/description_seller`);
  const { data: data12 } = useFETCH(`info-text/about_us`);

  let WebTitle = [
    {
      title: "وصف صفحة الاتصالات",
      description_ar: (
        <PopupTable
          description="وصف صفحة الاتصالات بالعربي"
          text={data?.data.data.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="وصف صفحة الاتصالات بالإنكليزي"
          text={data?.data.data.info_value?.en}
        />
      ),
      link: "/text-info/contact/description_contact",
    },
    {
      title: "وصف الاستخدام المقبول",
      description_ar: (
        <PopupTable
          description="وصف الاستخدام المقبول بالعربي"
          text={data1?.data.data.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="وصف الاستخدام المقبول بالإنكليزي"
          text={data1?.data.data.info_value?.en}
        />
      ),
      link: "/text-info/acceptable_use/acceptable_use",
    },
    {
      title: "وصف الاستفسار الالي",
      description_ar: (
        <PopupTable
          description="وصف الاستفسار الالي بالعربي"
          text={data2?.data.data.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="وصف الاستفسار الالي بالإنكليزي"
          text={data2?.data.data.info_value?.en}
        />
      ),
      link: "/text-info/automated_queries/automated_queries",
    },
    {
      title: "وصف الاقسام",
      description_ar: (
        <PopupTable
          description="وصف الاقسام بالعربي"
          text={data3?.data.data.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="وصف الاقسام بالإنكليزي"
          text={data3?.data.data.info_value?.en}
        />
      ),
      link: "/text-info/departments/description_departments",
    },
    {
      title: "وصف صفحة القادم قريبا",
      description_ar: (
        <PopupTable
          description="وصف صفحة القادم قريبا بالعربي"
          text={data4?.data.data?.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="وصف صفحة القادم قريبا بالإنكليزي"
          text={data4?.data.data?.info_value?.en}
        />
      ),
      link: "/text-info/launching/description_launching",
    },
    {
      title: "وصف صفحة تواصل معنا",
      description_ar: (
        <PopupTable
          description="وصف صفحة تواصل معنا بالعربي"
          text={data5?.data.data.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="وصف صفحة تواصل معنا بالإنكليزي"
          text={data5?.data.data.info_value?.en}
        />
      ),
      link: "/text-info/Contact-Us/address",
    },
    {
      title: "نص صفحة شروطنا",
      description_ar: (
        <PopupTable
          description="نص صفحة شروطنا بالعربي"
          text={data6?.data.data.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="نص صفحة شروطنا بالإنكليزي"
          text={data6?.data.data.info_value?.en}
        />
      ),
      link: "/text-info/terms/terms_and_conditions",
    },
    {
      title: "النص في مكان أعمالنا",
      description_ar: (
        <PopupTable
          description="النص في مكان أعمالنا بالعربي"
          text={data7?.data.data.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="النص في مكان أعمالنا بالإنكليزي"
          text={data7?.data.data.info_value?.en}
        />
      ),
      link: "/text-info/products/our_products",
    },
    {
      title: "النص في الصفحة الرئيسية",
      description_ar: (
        <PopupTable
          description="النص في الصفحة الرئيسية بالعربي"
          text={data8?.data.data.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="النص في الصفحة الرئيسية بالإنكليزي"
          text={data8?.data.data.info_value?.en}
        />
      ),
      link: "/text-info/home/title_home",
    },
    {
      title: "النص في صفحة التصميم",
      description_ar: (
        <PopupTable
          description="النص في صفحة التصميم بالعربي"
          text={data9?.data.data.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="النص في صفحة التصميم بالإنكليزي"
          text={data9?.data.data.info_value?.en}
        />
      ),
      link: "/text-info/design/design",
    },
    {
      title: "وصف البائع في صفحة كيف نعمل",
      description_ar: (
        <PopupTable
          description="وصف البائع في صفحة كيف نعمل بالعربي"
          text={data10?.data.data.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="وصف البائع في صفحة كيف نعمل بالإنكليزي"
          text={data10?.data.data.info_value?.en}
        />
      ),
      link: "/text-info/buyer/description_buyer",
    },
    {
      title: "وصف المشتري في صفحة كيف نعمل",
      description_ar: (
        <PopupTable
          description="وصف المشتري في صفحة كيف نعمل بالعربي"
          text={data11?.data.data.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="وصف المشتري في صفحة كيف نعمل بالإنكليزي"
          text={data11?.data.data.info_value?.en}
        />
      ),
      link: "/text-info/seller/description_seller",
    },
    {
      title: "وصف صفحة معلومات عنا",
      description_ar: (
        <PopupTable
          description="وصف صفحة معلومات عنا بالعربي"
          text={data12?.data.data.info_value?.ar}
        />
      ),
      description_en: (
        <PopupTable
          description="وصف صفحة معلومات عنا بالإنكليزي"
          text={data12?.data.data.info_value?.en}
        />
      ),
      link: "/text-info/about_us/about_us",
    },
  ];
  return (
    <div>
      <Title title="نصوص المشروع" />
      <Table
        thead={["اسم الصفحة", "الوصف بالعربي", "الوصف بالإنكليزي"]}
        tData={WebTitle}
        tbody={["title", "description_ar", "description_en"]}
        editText={true}
        hide={true}
        editDes={true}
        isLoading={load}
      />
    </div>
  );
}
export default TextInfo;
