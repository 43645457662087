import { useState } from "react";
import { useLocation } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Add, PopupTable, Table, Title } from "../../components";
import Filters from "../../components/Filter/Filters";
const Properties = () => {
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(
    `properties${search.length > 1 ? "&" + search.substring(1) : ""}`,
    "properties"
  );
  const [first, setFirst] = useState(false);
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      image: fileUrl + e.images[0]?.file,
      name_ar: e.name?.ar,
      address: <PopupTable description="الموقع بالعربي" text={e.address?.ar} />,
      description: (
        <PopupTable description="الوصف بالعربي" text={e.description?.ar} />
      ),
      phone_number: e.phone_number,
      whatsapp: e.whatsapp,
      // is_active: e.is_available,
    };
  });
  console.log(data?.data.data.data);
  return (
    <>
      <Filters setShow={setFirst} show={first} total={data?.data.data?.total} />
      <div
        onClick={() => setFirst(true)}
        className="w-fit bg-Main px-9 py-2 rounded-lg text-white cursor-pointer"
      >
        فلتر
      </div>
      <Title title="العقارات" />
      <Table
        isLoading={isLoading}
        thead={[
          "صورة العقار",
          "الاسم بالعربي",
          "الموقع بالعربي",
          "الوصف بالعربي",
          "رقم الهاتف",
          "رقم الواتس أب",
        ]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={dataAll}
        tbody={[
          "image",
          "name_ar",
          "address",
          "description",
          "phone_number",
          "whatsapp",
        ]}
        funDelete={deleteItem}
        edit="properties"
        show="properties/view"
      />
      {/* is_available */}
      <Add link={`/properties/add`} />
    </>
  );
};

export default Properties;
