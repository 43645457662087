import useFilter, { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Button from "../Buttons/Button";

const Filters = ({ total, show, setShow, types }) => {
  const { handleParamsClick, searchParams, handleParamsDelete } = useFilter();
  const { data } = useFETCH("property-type?local=none");
  return (
    <>
      {show && (
        <div>
          <div
            style={{ direction: "ltr" }}
            className="fixed h-full max-sm:w-[90%] w-1/2 bg-white bottom-0 z-[99999999] left-0 overflow-y-scroll chat overflow-x-hidden"
          >
            <div className="sticky top-0 bg-white py-2 px-3 flex justify-between items-center ">
              <div className="text-xl font-semibold pb-3">SearchOptions</div>
              <div
                onClick={() => setShow(false)}
                className="w-fit border px-4 py-2 rounded-2xl ml-auto text-xl font-semibold hover:bg-slate-300 hover:text-red-500 cursor-default"
              >
                X
              </div>
            </div>
            <div>
              <div className="bg-slate-200 my-3">
                <h1 className="text-xl font-semibold text-end mx-4 pb-3">
                  نوع العقار
                </h1>
                <Row>
                  {data?.data.data?.map((e) => (
                    <Rooms
                      nameFilter="property_type"
                      number={`${e.id}`}
                      name={e.name?.ar}
                    />
                  ))}
                </Row>
              </div>
              <div className="bg-slate-200 my-3">
                <h1 className="text-xl font-semibold pb-3 text-end mx-4">
                  الغرف
                </h1>
                <Row>
                  <Rooms nameFilter="rooms" number="1" />
                  <Rooms nameFilter="rooms" number="2" />
                  <Rooms nameFilter="rooms" number="3" />
                  <Rooms nameFilter="rooms" number="4" />
                  <Rooms nameFilter="rooms" number="5" plus={true} />
                </Row>
              </div>
              <div className="bg-slate-200 my-3 py-2 px-2">
                <h1 className="text-xl font-semibold pb-3 text-end mx-4">
                  الحمامات
                </h1>
                <Row>
                  <Rooms nameFilter="bathroom" number="1" />
                  <Rooms nameFilter="bathroom" number="2" />
                  <Rooms nameFilter="bathroom" number="3" />
                  <Rooms nameFilter="bathroom" number="4" />
                  <Rooms nameFilter="bathroom" number="5" plus={true} />
                </Row>
              </div>
              <div className="bg-slate-200 my-3 py-2 px-2">
                <h1 className="text-xl font-semibold pb-3 text-end mx-4">
                  سنوات التقسيط
                </h1>
                <Row>
                  <Rooms nameFilter="installment_years" number="1" />
                  <Rooms nameFilter="installment_years" number="2" />
                  <Rooms nameFilter="installment_years" number="3" />
                  <Rooms nameFilter="installment_years" number="4" />
                  <Rooms nameFilter="installment_years" number="5" />
                  <Rooms
                    nameFilter="installment_years"
                    number="6"
                    plus={true}
                  />
                </Row>
              </div>
              <div className="bg-slate-200 my-3 py-2 px-2">
                <h1 className="text-xl font-semibold pb-3 text-end mx-4">
                  تاريخ التسليم
                </h1>
                <Row>
                  <Rooms nameFilter="delivery_date" name="الان" number="now" />
                  <Rooms
                    nameFilter="delivery_date"
                    name="+2024"
                    number="date"
                  />
                </Row>
              </div>
              <div className="bg-slate-200 my-3 py-2 px-2">
                <h1 className="text-xl font-semibold pb-3 text-end mx-4">
                  المساحة بالمتر المربع
                </h1>
                <Row className="justify-center">
                  <Col col={5}>
                    <input
                      type="number"
                      className="py-2 px-4 rounded-xl text-xs font-semibold border-2 border-black text-end"
                      value={searchParams?.get("max_house_area")}
                      placeholder="الى"
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          handleParamsClick("max_house_area", e.target.value);
                        } else {
                          handleParamsDelete("max_house_area");
                        }
                      }}
                    />
                  </Col>
                  <Col col={5}>
                    <input
                      type="number"
                      className="py-2 px-4 rounded-xl text-xs font-semibold border-2 border-black text-end"
                      value={searchParams?.get("min_house_area")}
                      placeholder="من"
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          handleParamsClick("min_house_area", e.target.value);
                        } else {
                          handleParamsDelete("min_house_area");
                        }
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="bg-slate-200 my-3 py-2 px-2">
                <h1 className="text-xl font-semibold pb-3 text-end mx-4">
                  أنواع التشطيب
                </h1>
                <Row>
                  <Rooms
                    sm={6}
                    nameFilter="types_of_finishing"
                    number="1"
                    name="بدون تشطيب"
                  />
                  <Rooms
                    sm={6}
                    number="2"
                    nameFilter="types_of_finishing"
                    name="نصف تشطيب"
                  />
                  <Rooms
                    sm={6}
                    number="3"
                    nameFilter="types_of_finishing"
                    name="تشطيب كامل"
                  />
                  <Rooms
                    sm={6}
                    number="4"
                    nameFilter="types_of_finishing"
                    name="مفروش"
                  />
                </Row>
              </div>
              <Row
                justify="center"
                className="sticky bottom-0 bg-white py-2 px-3 "
              >
                <Col xs={6}>
                  <Button
                    onClick={() => {
                      handleParamsDelete("property_type");
                      handleParamsDelete("rooms");
                      handleParamsDelete("bathroom");
                      handleParamsDelete("installment_years");
                      handleParamsDelete("delivery_date");
                      handleParamsDelete("min_house_area");
                      handleParamsDelete("max_house_area");
                      handleParamsDelete("types_of_finishing");
                    }}
                    title="إلغاء الكل"
                    className="bg-white border border-black !text-Main hover:bg-slate-100"
                  />
                </Col>
                <Col xs={6}>
                  <Button
                    onClick={() => setShow(false)}
                    title={"إظهار النتائج " + total}
                    className="!bg-Main"
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div
            onClick={() => setShow(false)}
            className="fixed h-full  max-sm:w-[10%] w-1/2 bg-black/30 bottom-0 z-[99999999] right-0 "
          />
        </div>
      )}
    </>
  );
};

export default Filters;

const Rooms = ({ number, nameFilter, plus, name, sm, md }) => {
  const { handleParamsClick, searchParams, handleParamsDelete } = useFilter();
  return (
    <Col sm={sm || 4} md={md || 3}>
      <div
        onClick={() => {
          if (
            searchParams.has(nameFilter) &&
            searchParams?.get(nameFilter) === number
          ) {
            handleParamsDelete(nameFilter);
          } else {
            handleParamsClick(nameFilter, number);
          }
        }}
        className={`${
          searchParams?.get(nameFilter) === number
            ? "border-Main text-Main"
            : "border-black"
        } border-2 rounded-2xl flex items-center justify-center  cursor-pointer font-bold min-w-10 h-10 text-xs`}
      >
        {plus ? "+" + number : name ? name : number}
      </div>
    </Col>
  );
};
