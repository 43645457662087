import { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import useFilters from "../../Tools/APIs/useMyAPI";

function Search({ name }) {
  const { handleParamsClick } = useFilters();
  const [typingTimeout, setTypingTimeout] = useState("");
  return (
    <div className="">
      <div className="border-2 border-Main flex items-center justify-between space-x-2 p-2 rounded-xl">
        <input
          autoComplete="false"
          placeholder="بحث"
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            clearTimeout(typingTimeout);
            const newTypingTimeout = setTimeout(() => {
              handleParamsClick("search", value);
              if (name) {
                handleParamsClick("sort_by", name);
              }
            }, 1000);
            setTypingTimeout(newTypingTimeout);
          }}
        />
        <span>
          <IoSearchOutline size={20} />
        </span>
      </div>
    </div>
  );
}
export default Search;
