import { useLocation } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { CoinFilter, Table, Title } from "../../components";

function Coins() {
  const { search } = useLocation({});
  const { data, isLoading } = useFETCH(`coins${search}`, "coins");
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      country: e.country,
      code: e.code,
      coins: e.is_active,
      coins_type: e.is_active ? "متاحة" : "غير متاحة",
    };
  });
  return (
    <div>
      <Title title="العملات" />
      <CoinFilter />
      <Table
        isLoading={isLoading}
        thead={["الرقم", "اسم البلد", "العملة", "نوع العملة"]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={dataAll}
        tbody={["id", "country", "code", "coins_type"]}
      />
    </div>
  );
}
export default Coins;
