import { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FaCheck, FaEye } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import AcceptSeller from "../Operation/AcceptSeller";
import { MdBlock } from "react-icons/md";
const CardBox = ({
  deleted,
  children,
  deleteClick,
  edit,
  className,
  showEdit,
  show,
  accept,
  id,
  rout,
}) => {
  const [sure, setSure] = useState(false);
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-50 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[500px] max-sm:w-[320px] min-h-[200px] !overflow-hidden">
              <p className="font-semibold text-3xl text-center py-7">
                هل أنت متأكد من حذف العنصر ؟
              </p>
              <div className="flex items-center justify-center m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-Main my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={deleteClick}
                  >
                    نعم
                  </div>
                </div>

                <button
                  onClick={() => setSure(false)}
                  className="px-7 py-3 border border-Main my-2 rounded-xl text-Main cursor-pointer hover:bg-opacity-75"
                >
                  لا
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <div
        className={`border-2 border-Main rounded-2xl p-3 w-full h-full ${className}`}
      >
        <div className="flex justify-end gap-1 mb-1">
          {show && (
            <div className="text-blue-600 w-10 h-10 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center">
              <Link to={show || ""}>
                <FaEye size={30} onClick={() => setSure(true)} />
              </Link>
            </div>
          )}
          {deleted ? (
            ""
          ) : (
            <div className="w-10 h-10 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center">
              <RiDeleteBin5Line
                size={30}
                color="#CC2132"
                onClick={() => setSure(true)}
              />
            </div>
          )}
          {edit && (
            <div
              className={`${
                showEdit ? "hidden" : ""
              } w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer hover:bg-slate-200`}
            >
              <Link to={edit || ""}>
                <AiOutlineEdit size={30} color="#0099B4" />
              </Link>
            </div>
          )}
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};

export default CardBox;
{
  /* {accept ? (
            <AcceptSeller rout={rout} available="0" id={id} name="إلغاء">
              <FaCheck size={30} className=" text-green-700" />
            </AcceptSeller>
          ) : (
            <AcceptSeller rout={rout} available="1" id={id}>
              <MdBlock size={30} className=" text-red-700" />
            </AcceptSeller>
          )} */
}
