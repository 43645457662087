import { RiArrowDropDownFill } from "react-icons/ri";
import { fileUrl, useClose, useFETCH } from "../../Tools/APIs/useMyAPI";
import user from "../../images/icon-user-default.png";

function ImageList() {
  const { mouse, setOpen, open } = useClose();
  const { data } = useFETCH(
    localStorage.getItem("token") ? `admin/profile` : ""
  );
  let dataAll = data?.data.data;
  console.log(dataAll);
  return (
    <div>
      <div
        className="relative cursor-pointer"
        ref={mouse}
        onClick={() => setOpen(!open)}
      >
        <div className="flex items-center justify-center gap-1">
          {dataAll?.image ? (
            <img
              src={fileUrl + dataAll?.image}
              alt=""
              className="w-12 h-12 rounded-full"
            />
          ) : (
            <img src={user} alt="" className="w-12 h-12 rounded-full" />
          )}
          <p className="ml-1">{dataAll?.name}</p>
          <RiArrowDropDownFill
            color="#000"
            size={35}
            className={`${
              open ? "rotate-180 transition-all" : "rotate-0 transition-all"
            }`}
          />
        </div>
        <ul
          className={`${
            open
              ? "absolute top-12 right-4 text-center bg-white shadow-lg border w-full p-2 rounded-lg hover:bg-Main hover:text-white"
              : "hidden"
          }`}
        >
          <p>{dataAll?.username && dataAll?.username}</p>
          <p>{dataAll?.phone_number && dataAll?.phone_number}</p>
        </ul>
      </div>
    </div>
  );
}
export default ImageList;
