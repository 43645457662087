import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { Add, CardBox, Title } from "../../components";
const ImageSlider = ({ type, title }) => {
  const { data, isLoading, deleteItem } = useFETCH(
    `slider-image?type=${type}`,
    `slider-image`
  );
  return (
    <>
      <Title title={title} />
      {data?.data.data.length === 0 && (
        <p className="text-lg font-semibold text-center">
          لا يوجد صور ليتم عرضها
        </p>
      )}
      {isLoading ? (
        <Row>
          <Loading />
          <Loading />
          <Loading />
          <Loading />
        </Row>
      ) : (
        <Row>
          {data?.data.data.map((e) => (
            <Col lg={3} md={4} xs={6} key={e.id}>
              <CardBox showEdit="true" deleteClick={() => deleteItem(e)}>
                <img
                  src={fileUrl + e.image}
                  alt=""
                  className="w-full h-[200px] rounded-3xl"
                />
              </CardBox>
            </Col>
          ))}
        </Row>
      )}
      <Add link={`${type}`} />
    </>
  );
};

export default ImageSlider;

const Loading = () => {
  return (
    <Col lg={3} md={4} xs={6}>
      <div className="w-full h-[230px] rounded-2xl overflow-hidden">
        <div className="bg-slate-300 animate-pulse w-full h-full"></div>
      </div>
    </Col>
  );
};
