import React from "react";
import { CiSearch } from "react-icons/ci";
import { useLocation } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { Add, Search, Table, Title } from "../../components";

const Users = () => {
  const { search } = useLocation({});
  const { data, isLoading, deleteItem } = useFETCH(`users${search}`, "users");
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      name: e.name,
      email: e.email,
      seller: e.seller,
    };
  });
  return (
    <div>
      <Row className="" justify={"between"}>
        <Col md={4}>
          <Title title="المستخدمين" />
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={6}>
          <Search />
        </Col>
      </Row>
      <Table
        isLoading={isLoading}
        thead={["الرقم", "اسم المستخدم", "الإيميل"]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={dataAll}
        tbody={["id", "name", "email"]}
        funDelete={deleteItem}
        edit="users"
        build="users/property"
        buildText="العقارات الخاصة بهذا المستخدم"
      />
      <Add link="/users/add" />
    </div>
  );
};

export default Users;
