import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Add, Table, Title } from "../../components";
const Attached = () => {
  const { data, isLoading, deleteItem } = useFETCH(`attacheds`, "attacheds");
  const dataAll = data?.data?.data?.map((e) => {
    return {
      id: e.id,
      image: fileUrl + e.icon,
      name_ar: e.name?.ar,
      name_en: e.name?.en,
    };
  });
  return (
    <>
      <Title title="المرفقات" />
      <Table
        isLoading={isLoading}
        thead={[
          "الرقم",
          "أيقونة المرفق",
          "اسم المرفق بالعربي",
          "اسم المرفق بالإنكليزي",
        ]}
        tData={dataAll}
        tbody={["id", "image", "name_ar", "name_en"]}
        funDelete={deleteItem}
        edit="attached"
      />
      <Add link="/attached/add" />
    </>
  );
};

export default Attached;
