import { useLocation } from "react-router-dom";
import useFilters from "../../Tools/APIs/useMyAPI";
import { Col } from "../../Tools/Grid-system";

const DeleteParamsAll = () => {
  const { handleParamsDeleteAll } = useFilters();
  const { search } = useLocation();
  return (
    <Col lg={2} md={4} xs={6}>
      <div
        className={`border-2 border-Main rounded-xl hover:bg-opacity-70 text-center cursor-pointer w-full px-5 py-2
       ${search ? " bg-white text-Main " : "bg-Main text-white "} `}
        onClick={() => handleParamsDeleteAll()}
      >
        الكل
      </div>
    </Col>
  );
};

export default DeleteParamsAll;
