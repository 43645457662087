import { useParams } from "react-router-dom";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Data, Loading, Title } from "../../components";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";

function ViewProperty() {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`properties/${id}?local=none`);
  let dataAll = data?.data.data;
  return (
    <div>
      <Container>
        {isLoading ? <Loading /> : ""}
        <Title title="الطالب" />
        <Col className="bg-white mx-auto border-2 border-Secondary py-5 px-3 rounded-xl">
          <Row className="justify-center">
            <Col md={3}>
              <Data name="الاسم بالعربي" text={dataAll?.property?.name.ar} />
            </Col>
            <Col md={3}>
              <Data name="الاسم بالأنكليزي" text={dataAll?.property?.name.en} />
            </Col>
            <Col lg={3} md={4}>
              <Data name="المساحة" text={dataAll?.property?.house_area} />
            </Col>
            <Col lg={3} md={4}>
              <Data name="عدد الحمامات" text={dataAll?.property?.bathroom} />
            </Col>
            <Col lg={3} md={4}>
              <Data name="عدد الغرف" text={dataAll?.property?.rooms} />
            </Col>
            <Col lg={3} md={4}>
              <Data name="السعر" text={dataAll?.property?.price} />
            </Col>
            <Col lg={3} md={4}>
              <Data name="رقم الهاتف" text={dataAll?.property?.phone_number} />
            </Col>
            <Col lg={3} md={4}>
              <Data
                name="تاريخ الاستلام "
                text={dataAll?.property?.delivery_date}
              />
            </Col>
            <Col lg={3} md={4}>
              <Data
                name="الدفعة الأولية"
                text={dataAll?.property?.initial_payment}
              />
            </Col>
            <Col lg={3} md={4}>
              <Data name="رقم الواتس" text={dataAll?.property?.whatsapp} />
            </Col>
            <Col lg={3} md={4}>
              <Data
                name="سنوات التقسيط"
                text={dataAll?.property?.installment_years}
              />
            </Col>
            <Col lg={3} md={4}>
              <Data
                name="أشهر التقسيط"
                text={dataAll?.property?.monthly_installment}
              />
            </Col>
            <Col md={6}>
              <Data
                name="الموقع بالعربي"
                text={dataAll?.property?.address.ar}
              />
            </Col>
            <Col md={6}>
              <Data
                name="الموقع بالأنكليزي"
                text={dataAll?.property?.address.en}
              />
            </Col>
            <Col md={6}>
              <Data
                name="خطط الدفع بالعربي"
                text={dataAll?.property?.payment_plans?.ar}
              />
            </Col>
            <Col md={6}>
              <Data
                name="خطط الدفع بالإنكليزي"
                text={dataAll?.property?.payment_plans?.en}
              />
            </Col>
            <Col lg={6}>
              <Data
                name="الوصف بالعربي"
                text={dataAll?.property?.description?.ar}
              />
            </Col>
            <Col lg={6}>
              <Data
                name="الوصف بالإنكليزي"
                text={dataAll?.property?.description?.en}
              />
            </Col>
            <Col>
              <Data name="رابط الموقع" href={dataAll?.property.url_location} />
            </Col>
            {dataAll?.attachedArray ? (
              <>
                <h2 className="font-bold text-red-600 my-5 text-center">
                  المرفقات
                </h2>
                <Row className="justify-center gap-1">
                  {dataAll?.attachedArray.map((e) => (
                    <Col
                      lg={4}
                      xs={6}
                      className="border-2 border-Main p-5 rounded-2xl"
                    >
                      <img
                        src={fileUrl + e.icon}
                        alt=""
                        className="h-[70px] w-[70px] rounded-full border mx-auto"
                      />
                      <Data name="الاسم بالعربي" text={e.name?.ar} />
                      <Data name="الاسم بالإنكليزي" text={e.name?.en} />
                    </Col>
                  ))}
                </Row>
              </>
            ) : (
              ""
            )}
            {dataAll?.property.image_location ? (
              <>
                <h2 className="font-bold text-red-600 my-5 text-center">
                  الخريطة
                </h2>
                <Col
                  lg={4}
                  xs={6}
                  className="border-2 border-Main p-5 rounded-2xl"
                >
                  <img
                    src={fileUrl + dataAll?.property.image_location}
                    alt=""
                    className="h-[140px] "
                  />
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
        </Col>
      </Container>
    </div>
  );
}
export default ViewProperty;
