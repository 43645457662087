import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "./Tools/APIs/useMyAPI";
import { Container } from "./Tools/Grid-system";
import Logo from "./images/Logo.png";
import { Navbar, SideBar } from "./layout/index";
import * as page from "./pages/index";
const App = () => {
  return (
    <>
      <div className="relative flex">
        <SideBar />
        <div className="container mx-auto relative">
          <Navbar />
          <Container>
            <Routes>
              <Route path="login" element={<page.Login />} />
              <Route path="forget-password" element={<page.ForgetPassword />} />
              <Route path="verification-code" element={<page.Code />} />
              <Route path="set-password" element={<page.SetPassword />} />
              <Route element={<RequireAuth />}>
                <Route
                  path=""
                  element={
                    <h1 className="grid place-content-center h-[80vh]">
                      <img src={Logo} alt="" className="w-[250px]" />
                    </h1>
                  }
                />
                <Route
                  path="*"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      404 | no page found
                    </h1>
                  }
                />
                <Route
                  path="403"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      403 | FORBIDDEN
                    </h1>
                  }
                />
                <Route
                  path="500"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      500 | Internal Server Error
                    </h1>
                  }
                />
                <Route path="home">
                  <Route
                    index
                    element={
                      <page.Home type="HOME" title="سلايدر الصفحة الرئيسية" />
                    }
                  />
                  <Route path="HOME" element={<page.EditHome type="HOME" />} />
                </Route>
                <Route path="design">
                  <Route
                    index
                    element={
                      <page.Home type="DESIGN" title="سلايدر صفحة التصميم" />
                    }
                  />
                  <Route
                    path="DESIGN"
                    element={<page.EditHome type="DESIGN" />}
                  />
                </Route>
                <Route path="our_products">
                  <Route
                    index
                    element={
                      <page.Home
                        type="OUR_PRODUCTS"
                        title="سلايدر صفحة منتجاتنا"
                      />
                    }
                  />
                  <Route
                    path="OUR_PRODUCTS"
                    element={<page.EditHome type="OUR_PRODUCTS" />}
                  />
                </Route>
                <Route path="users">
                  <Route index element={<page.Users />} />
                  <Route path=":id" element={<page.AddUser />} />
                  <Route
                    path="property/:id"
                    element={<page.UsersProperties />}
                  />
                </Route>
                <Route path="Contact-Us">
                  <Route index element={<page.ContactUs />} />
                  <Route path="edit" element={<page.UpdateContact />} />
                </Route>
                <Route path="message" element={<page.Message />} />
                <Route path="attached">
                  <Route index element={<page.Attached />} />
                  <Route path=":id" element={<page.AddAttached />} />
                </Route>
                <Route path="property-type">
                  <Route index element={<page.PropertyType />} />
                  <Route path=":id" element={<page.AddPropertyType />} />
                </Route>
                <Route path="properties">
                  <Route index element={<page.Properties />} />
                  <Route path=":id" element={<page.AddProperties />} />
                  <Route path="view/:id" element={<page.ViewProperty />} />
                </Route>
                <Route path="founders">
                  <Route
                    index
                    element={
                      <page.Founders
                        name="المؤسسون"
                        rout="founders"
                        api="FOUNDER"
                      />
                    }
                  />
                  <Route
                    path=":id"
                    element={<page.AddFounders api="FOUNDER" />}
                  />
                </Route>
                <Route path="news">
                  <Route
                    index
                    element={
                      <page.Founders name="الأخبار" rout="news" api="NEWS" />
                    }
                  />
                  <Route path=":id" element={<page.AddFounders api="NEWS" />} />
                </Route>
                <Route path="Compounds">
                  <Route index element={<page.Compounds />} />
                  <Route path=":id" element={<page.EditCompounds />} />
                  <Route path="show/:id" element={<page.CompoundsDetails />} />
                </Route>
                <Route path="area">
                  <Route index element={<page.Areas />} />
                  <Route path=":id" element={<page.EditArea />} />
                </Route>
                <Route path="coins">
                  <Route index element={<page.Coins />} />
                </Route>
                <Route path="launches">
                  <Route index element={<page.Launches />} />
                  <Route path=":id" element={<page.LaunchesEdit />} />
                </Route>
                <Route path="blogs">
                  <Route index element={<page.Blogs />} />
                  <Route path=":id" element={<page.EditBlogs />} />
                </Route>
                <Route path="text-info">
                  <Route index element={<page.TextInfo />} />
                  <Route path="contact/:id" element={<page.EditTextInfo />} />
                  <Route
                    path="acceptable_use/:id"
                    element={<page.EditTextInfo />}
                  />
                  <Route
                    path="automated_queries/:id"
                    element={<page.EditTextInfo />}
                  />
                  <Route
                    path="departments/:id"
                    element={<page.EditTextInfo />}
                  />
                  <Route path="launching/:id" element={<page.EditTextInfo />} />
                  <Route
                    path="contact-us/:id"
                    element={<page.EditTextInfo />}
                  />
                  <Route path="terms/:id" element={<page.EditTextInfo />} />
                  <Route path="products/:id" element={<page.EditTextInfo />} />
                  <Route path="home/:id" element={<page.EditTextInfo />} />
                  <Route path="design/:id" element={<page.EditTextInfo />} />
                  <Route path="buyer/:id" element={<page.EditTextInfo />} />
                  <Route path="seller/:id" element={<page.EditTextInfo />} />
                  <Route path="about_us/:id" element={<page.EditTextInfo />} />
                </Route>
                <Route path="orders">
                  <Route index element={<page.Orders />} />
                  <Route path=":id" element={<page.AddProperties />} />
                  <Route path="view/:id" element={<page.ViewProperty />} />
                </Route>
                <Route path="sell">
                  <Route index element={<page.Seller />} />
                  <Route path="details/:id" element={<page.SellerDetails />} />
                </Route>
                <Route
                  path="Change-Password"
                  element={<page.ChangePassword />}
                />
              </Route>
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
};

export default App;
