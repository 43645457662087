import { FaCheck } from "react-icons/fa";
import { MdBlock, MdClose, MdOutlineEventAvailable } from "react-icons/md";
import { clickZoomInImage } from "../../Tools/APIs/useMyAPI";
import Pagination from "../../Tools/Pagination";
import Delete from "../Operation/Delete";
import IsActive from "../Operation/IsActive";
import SellerActive from "../Operation/SellerActive";
import Show from "../Operation/Show";
import Update from "../Operation/Update";

const Table = ({
  thead,
  tbody,
  tData,
  show,
  funDelete,
  pageCount,
  edit,
  hide,
  isLoading,
  showText,
  updateText,
  build,
  buildText,
  editDes,
  deleteText,
  status,
}) => {
  return (
    <div>
      <Pagination pageCount={pageCount} isLoading={isLoading}>
        <div className="mx-auto w-full py-4 overflow-x-auto h-full pb-5">
          <table className="min-w-[750px] mx-auto border overflow-hidden rounded-2xl h-full bg-white">
            <thead className="bg-Main text-white h-[30px] text-center">
              <tr>
                {thead?.map((e, i) => (
                  <td key={i} className="py-3 px-5 text-sm font-semibold">
                    {e}
                  </td>
                ))}
                {status && (
                  <td className={`py-3 px-5 text-sm font-semibold`}>
                    حالات الطلب
                  </td>
                )}
                <td className={`${hide ? "hidden" : "px-5 py-3 w-fit"}`}>
                  العمليات
                </td>
                {editDes && <td className={`px-2 py-3`}>تعديل النص</td>}
              </tr>
            </thead>
            <tbody>
              {tData &&
                tData?.map((e, i) => (
                  <tr
                    key={i}
                    className="border border-Main bg-gradient-to-t from-gray-200"
                  >
                    {tbody?.map((name, i) => (
                      <>
                        <td key={i} className={`py-1 px-2 text-center`}>
                          {typeof e[name] === "string" &&
                          e[name].substring(0, 4) === "http" ? (
                            <img
                              src={e[name]}
                              alt=""
                              className="w-14 h-14 rounded-full mx-auto cursor-pointer"
                              onClick={clickZoomInImage}
                            />
                          ) : e[name] ? (
                            <div>{e[name]}</div>
                          ) : (
                            "___"
                          )}
                        </td>
                      </>
                    ))}
                    {e.link && (
                      <td className={"px-2 flex justify-center"}>
                        <Update edit={`${e.link}`} updateText={updateText} />
                      </td>
                    )}
                    {status && (
                      <td
                        className={`${
                          e.status === "WAITING"
                            ? "text-blue-600"
                            : e.status === "COMPLETED"
                            ? "text-green-600"
                            : e.status === "FORBIDDEN"
                            ? "text-purple-600"
                            : "text-red-600"
                        }  font-semibold text-center py-7`}
                      >
                        {e.status === "WAITING"
                          ? "بالإنتظار"
                          : e.status === "COMPLETED"
                          ? "مقبول"
                          : e.status === "FORBIDDEN"
                          ? "محظور"
                          : "ملغي"}
                      </td>
                    )}
                    <td
                      className={`${
                        hide ? "hidden" : "cursor-pointer relative px-2"
                      }`}
                    >
                      <div className="flex justify-end mx-auto items-center gap-3">
                        {show && (
                          <Show show={`/${show}/${e.id}`} text={showText} />
                        )}
                        {e.seller ? (
                          <Show
                            build={true}
                            show={`/${build}/${e.id}`}
                            text={buildText}
                          />
                        ) : (
                          ""
                        )}
                        {edit && (
                          <Update
                            edit={`/${edit}/${e.id}`}
                            updateText={updateText}
                          />
                        )}
                        {e.status !== "CANCELED" ? (
                          <SellerActive
                            text="الطلب"
                            id={e.id}
                            active="CANCELED"
                            name="إلغاء"
                          >
                            <MdClose size={30} className=" text-red-700" />
                          </SellerActive>
                        ) : (
                          e.status && <div className="w-10 h-10" />
                        )}
                        {e.status ? (
                          <SellerActive
                            text="الطلب"
                            id={e.id}
                            active="FORBIDDEN"
                            name="حظر"
                          >
                            <MdBlock size={30} className=" text-purple-700" />
                          </SellerActive>
                        ) : (
                          e.status && <div className="w-10 h-10" />
                        )}
                        {e.status !== "COMPLETED" ? (
                          <SellerActive
                            text="الطلب"
                            id={e.id}
                            active="COMPLETED"
                            name="قبول"
                          >
                            <FaCheck size={30} className=" text-green-700" />
                          </SellerActive>
                        ) : (
                          e.status && <div className="w-10 h-10" />
                        )}
                        {e.coins === 0 && (
                          <IsActive id={e.id} active="COMPLETED">
                            <MdOutlineEventAvailable
                              size={30}
                              className=" text-green-700"
                            />
                          </IsActive>
                        )}
                        {e.coins === 1 && (
                          <IsActive id={e.id} active="0" name="غير">
                            <MdBlock size={30} className=" text-red-700" />
                          </IsActive>
                        )}
                        {funDelete && (
                          <Delete
                            id={e.id}
                            deleteText={deleteText}
                            deleteClick={() => funDelete(e)}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Pagination>
    </div>
  );
};

export default Table;
