import { useFilter } from "../../Tools/APIs/useMyAPI";
import { Col } from "../../Tools/Grid-system";

const FilerBtn = ({ value, name, title }) => {
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  return (
    <Col lg={2} md={4} xs={6}>
      <div
        className={`border-2  border-Main bg-white text-Main rounded-xl hover:bg-opacity-70 text-center font-semibold cursor-pointer w-full py-2
                    ${
                      searchParams?.get(name) === value
                        ? "!bg-Main text-white"
                        : ""
                    }`}
        onClick={() => {
          if (name) {
            if (searchParams?.get(name) === value) {
              handleParamsDelete(name);
            } else {
              handleParamsClick(name, value);
            }
          }
        }}
      >
        {title}
      </div>
    </Col>
  );
};

export default FilerBtn;
