import { Row } from "../../Tools/Grid-system";
import DeleteParamsAll from "./DeleteParamsAll";
import FilerBtn from "./FilerBtn";

function PropertyTypeFilter() {
  return (
    <div>
      <Row className="my-5">
        <DeleteParamsAll />
        <FilerBtn name="type" title="سكني" value="RESIDENTIAL" />
        <FilerBtn name="type" title="تجاري" value="COMMERCIAL" />
      </Row>
    </div>
  );
}
export default PropertyTypeFilter;
