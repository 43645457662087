import { BiBuildingHouse, BiCategory } from "react-icons/bi";
import { BsBookFill, BsBuildingAdd, BsBuildingsFill } from "react-icons/bs";
import { CiTextAlignCenter } from "react-icons/ci";
import { FaBuilding, FaRegNewspaper, FaUsers } from "react-icons/fa";
import { GiSellCard } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { ImCoinEuro } from "react-icons/im";
import { IoIosHome } from "react-icons/io";
import { LuBuilding2, LuPhoneCall } from "react-icons/lu";
import {
  MdDesignServices,
  MdOutlineMessage,
  MdOutlineOpenInNew,
  MdPassword,
} from "react-icons/md";
import { RiBuildingLine, RiLogoutBoxRLine } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
import { logout } from "../../Tools/APIs/useMyAPI";
import { Popup } from "../../components";
import logo from "../../images/Logo.png";
import "./SideBar.css";
function SideBar() {
  const { openMenu, changeMenu } = useContextHook();
  const sidebar = [
    { icon: IoIosHome, name: "الصفحة الرئيسية", link: "/Home" },
    { icon: GiSellCard, name: "طلبات أذن البيع", link: "/sell" },
    { icon: FaBuilding, name: "طلبات العقار", link: "/orders" },
    { icon: FaUsers, name: "المستخدمين", link: "/Users" },
    { icon: BsBuildingsFill, name: "المناطق", link: "/area" },
    { icon: LuBuilding2, name: "المرفقات", link: "/attached" },
    { icon: BiBuildingHouse, name: "نوع العقار", link: "/property-type" },
    { icon: HiUserGroup, name: "المُؤسسون", link: "/founders" },
    { icon: FaRegNewspaper, name: "الأخبار", link: "/news" },
    { icon: LuPhoneCall, name: "تواصل معنا", link: "/Contact-Us" },
    { icon: MdOutlineOpenInNew, name: "قادم قريباً", link: "/launches" },
    { icon: MdOutlineMessage, name: "الرسائل", link: "/Message" },
    { icon: ImCoinEuro, name: "العملات", link: "/coins?is_active=1" },
    { icon: BsBookFill, name: "المنتدى", link: "/blogs" },
    { icon: BsBuildingAdd, name: "المجمع", link: "/compounds" },
    { icon: RiBuildingLine, name: "العقارات", link: "/properties" },
    { icon: MdDesignServices, name: "التصميم", link: "/design" },
    { icon: BiCategory, name: "منتجاتنا", link: "/our_products" },
    { icon: CiTextAlignCenter, name: "نصوص المشروع", link: "/text-info" },
    { icon: MdPassword, name: "تغيير كلمة السر", link: "/Change-Password" },
  ];
  return (
    <>
      <div
        className={`${
          openMenu
            ? "!w-[220px] max-sm:!w-[250px] !max-lg:translate-x-10 !max-lg:w-3/4 -!right-10"
            : " w-[0px]"
        } !max-lg:translate-x-full bg-Main sidebar lg:h-[95vh] h-[100vh] mt-0 lg:mt-5 lg:rounded-2xl sideBar max-lg:h-[100%] sticky max-lg:fixed lg:left-10 left-0 lg:right-10 right-0 top-0 transition-all overflow-y-auto py-4 z-40 max-lg:z-50 `}
      >
        <div className="px-3">
          <ul className="font-semibold space-y-2">
            <Link to="/">
              <img
                src={logo}
                alt=""
                className="bg-white rounded-2xl m-2 mx-auto object-contain"
              />
            </Link>

            {sidebar.map((e, i) => (
              <li className="" key={i}>
                <NavLink
                  to={e.link}
                  className="flex items-center gap-2 py-2 px-2 rounded-lg text-sm text-white hover:bg-white hover:text-Main transition-all"
                >
                  <e.icon size={20} />
                  <p>{e.name}</p>
                </NavLink>
              </li>
            ))}
            <li>
              <Popup
                id={`logout`}
                description="هل انت متأكد من تسجيل الخروج ؟"
                onClick={() => logout("admin/logout")}
                hover={true}
              >
                <div className="flex items-center gap-2 py-2 px-2 cursor-pointer rounded-lg text-sm text-white hover:bg-white hover:text-Main transition-all">
                  <RiLogoutBoxRLine size={20} />
                  <p>تسجيل خروج</p>
                </div>
              </Popup>
            </li>
          </ul>
        </div>
      </div>
      <div
        onClick={changeMenu}
        style={{ transition: "0.4s" }}
        className={`${
          openMenu ? "" : "hidden"
        }  max-lg:w-full h-full bg-black/20 fixed -left-0 top-0 transition-all z-40`}
      />
    </>
  );
}

export default SideBar;
