import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import {
  Add,
  PopupTable,
  Table,
  Title
} from "../../components";

function Blogs() {
  const { data, isLoading, deleteItem } = useFETCH(
    `blogs`,
    "blogs"
  );
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      image: fileUrl + e.images[0]?.image,
      name_ar: e.name?.ar,
      name_en: e.name?.en,
      small_text_ar: <PopupTable description="النص القصير بالعربي" text={e.small_text?.ar} />,
      small_text_en: <PopupTable description="النص القصير بالإنكليزي" text={e.small_text?.en} />,
      large_text_ar: <PopupTable description="النص الطويل بالعربي" text={e.large_text?.ar} />,
      large_text_en: <PopupTable description="النص الطويل بالإنكليزي" text={e.large_text?.en} />,
    };
  });
  return (
    <div>
      <Title title="المنتدى" />
      <Table
        isLoading={isLoading}
        thead={[
          "صورة المنتدى",
          "الاسم بالعربي",
          "الاسم بالإنكليزي",
          "النص القصير بالعربي",
          "النص القصير بالإنكليزي",
          "النص الطويل بالعربي",
          "النص الطويل بالإنكليزي",
        ]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={dataAll}
        tbody={[
          "image",
          "name_ar",
          "name_en",
          "small_text_ar",
          "small_text_en",
          "large_text_ar",
          "large_text_en",
        ]}
        funDelete={deleteItem}
        edit="blogs"
      />
      <Add link={`/blogs/add`} />
    </div>
  );
}
export default Blogs;
