import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import {
  AddImage,
  Back,
  ButtonRed,
  CheckInput,
  HTMLInput,
  Input,
  Loading,
  Selected,
  Title,
} from "../../components";
const animatedComponents = makeAnimated();
const AddProperties = () => {
  const { id } = useParams();
  let AddId = id === "add";
  const [deleteImgs, setDeleteImgs] = useState();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    handleCheckedArray,
    setViewImages,
    handleChangeArrayImages,
    setImages,
    setCheckArray,
    images,
    viewImages,
    formData,
    loading,
    error,
  } = usePOST({ trending: 0, is_available: 0 });
  const { data: dataCompound, isLoading } = useFETCH(`compounds`);
  const { data: dataPropertyType } = useFETCH(`property-type`);
  const { data: dataCoins } = useFETCH(`coins?is_active=1`);
  const { data: dataAttached } = useFETCH(`attacheds`);
  const { data } = useFETCH(AddId ? "" : `properties/${id}?local=none`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(AddId ? `properties` : `properties/${id}`);
  };
  let dataOld = data?.data.data?.property;
  useEffect(() => {
    !AddId &&
      setFormData({
        ar_name: dataOld?.name?.ar,
        en_name: dataOld?.name?.en,
        ar_address: dataOld?.address?.ar,
        en_address: dataOld?.address?.en,
        ar_description: dataOld?.description?.ar,
        en_description: dataOld?.description?.en,
        ar_payment_plans: dataOld?.payment_plans?.ar,
        en_payment_plans: dataOld?.payment_plans?.en,
        whatsapp: dataOld?.whatsapp,
        phone_number: dataOld?.phone_number,
        url_location: dataOld?.url_location,
        initial_payment: dataOld?.initial_payment,
        monthly_installment: dataOld?.monthly_installment,
        installment_years: dataOld?.installment_years,
        delivery_date: dataOld?.delivery_date,
        types_of_finishing: dataOld?.types_of_finishing,
        rooms: dataOld?.rooms,
        bathroom: dataOld?.bathroom,
        house_area: dataOld?.house_area,
        compound_id: dataOld?.compound_id,
        coin_id: dataOld?.coin_id,
        trending: dataOld?.trending,
        price: dataOld?.price,
        is_available: dataOld?.is_available,
        property_type: dataOld?.property_type,
      });
    setSelectedAttached(
      data?.data.data.attachedArray?.map((e) => {
        return { value: e.id, label: e.name?.ar };
      })
    );
    setCheckArray({
      "attached[]": selectedAttached?.map((e) => [e.value]),
    });
    setSelectedCoins(
      CoinsOption?.filter((coin) => coin.value === +formData?.coin_id)[0]
    );
    setSelectedCompound(
      compoundOption?.filter(
        (Compound) => Compound.value === +formData?.compound_id
      )[0]
    );
    setSelectedPropertyType(
      propertyTypeOption?.filter(
        (PropertyType) => PropertyType.value === +formData?.property_type
      )[0]
    );
  }, [dataOld]);
  const compoundOption = dataCompound?.data.data.data.map((e) => {
    return { value: e.id, label: e.name.ar };
  });
  const propertyTypeOption = dataPropertyType?.data.data.map((e) => {
    return { value: e.id, label: e.name.ar };
  });
  const CoinsOption = dataCoins?.data.data.data.map((e) => {
    return { value: e.id, label: e.code };
  });
  const attachedOption = dataAttached?.data.data.map((e) => {
    return { value: e.id, label: e.name?.ar };
  });
  const [selectedAttached, setSelectedAttached] = useState([]);
  const [selectedCoins, setSelectedCoins] = useState();
  const [selectedCompound, setSelectedCompound] = useState();
  const [selectedPropertyType, setSelectedPropertyType] = useState();
  const dataInput = [
    { md: 6, type: "string", name: "ar_name", title: "اسم العقار بالعربي" },
    { md: 6, type: "string", name: "en_name", title: "اسم العقار بالإنكليزي" },
    { md: 6, type: "string", name: "ar_address", title: "موقع العقار بالعربي" },
    {
      md: 6,
      type: "string",
      name: "en_address",
      title: "موقع العقار بالإنكليزي",
    },
    { md: 4, type: "number", name: "whatsapp", title: "رقم الواتس " },
    { md: 4, type: "number", name: "phone_number", title: "رقم الجوال " },
    { md: 4, type: "number", name: "rooms", title: "عدد الغرف" },
    { md: 4, type: "number", name: "bathroom", title: "عدد الحمامات" },
    {
      md: 4,
      type: "number",
      name: "house_area",
      title: "المساحة بالمتر المربع",
    },
    { md: 4, type: "number", name: "price", title: "السعر" },
    { md: 4, type: "number", name: "initial_payment", title: "الدفعة الأولية" },
    {
      md: 4,
      type: "number",
      name: "monthly_installment",
      title: "القسط الشهري",
    },
    {
      md: 4,
      type: "number",
      name: "installment_years",
      title: "سنوات التقسيط",
    },
    { md: 6, type: "date", name: "delivery_date", title: "تاريخ التسليم" },
    { md: 6, type: "string", name: "url_location", title: "رابط الموقع" },
  ];
  return (
    <Container className="px-1">
      <Title title={AddId ? "أضف عقار" : "تعديل عقار"} />
      <Row
        justify="center"
        className="items-center border-2 border-Secondary rounded-xl"
      >
        {dataInput.map((e, i) => {
          return (
            <Col md={e.md} key={i}>
              <Input
                type={e.type}
                name={e.name}
                title={e.title}
                value={formData?.[e.name]}
                onChange={handleChangeInput}
              />
            </Col>
          );
        })}
        <Col>
          <HTMLInput
            title="وصف العقار بالعربي"
            value={formData?.ar_description}
            onChange={(e) => setFormData({ ...formData, ar_description: e })}
          />
        </Col>
        <Col>
          <HTMLInput
            title="وصف العقار بالإنكليزي"
            value={formData?.en_description}
            onChange={(e) => setFormData({ ...formData, en_description: e })}
          />
        </Col>
        <Col md={6}>
          <HTMLInput
            title="خطط الدفع بالعربي"
            value={formData?.ar_payment_plans}
            onChange={(e) => setFormData({ ...formData, ar_payment_plans: e })}
          />
        </Col>
        <Col md={6}>
          <HTMLInput
            title="خطط الدفع بالإنكليزي"
            value={formData?.en_payment_plans}
            onChange={(e) => setFormData({ ...formData, en_payment_plans: e })}
          />
        </Col>
        <Col md={6} className="my-3 px-1">
          <h1 className={`text-end mb-2`}>المرفقات</h1>
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            name="attached[]"
            value={selectedAttached}
            onChange={(selectedOptions) => {
              const selectedValues = selectedOptions?.map((option) => [
                option.value,
              ]);
              setSelectedAttached(selectedOptions);
              setCheckArray({ "attached[]": selectedValues });
            }}
            options={attachedOption}
          />
        </Col>
        <Col md={6} className="my-3 px-1">
          <h1 className={`mb-2`}>العملة</h1>
          <Select
            options={CoinsOption}
            name="coin_id"
            value={selectedCoins}
            onChange={(selectedCoin) => {
              setSelectedCoins(selectedCoin);
              setFormData({ ...formData, coin_id: selectedCoin.value });
            }}
          />
        </Col>
        <Col md={6} className="my-3 px-1">
          <h1 className={`mb-2`}>نوع العقار</h1>
          <Select
            options={propertyTypeOption}
            name="property_type"
            value={selectedPropertyType}
            onChange={(selectedProperty) => {
              setSelectedPropertyType(selectedProperty);
              setFormData({
                ...formData,
                property_type: selectedProperty.value,
              });
            }}
          />
        </Col>
        <Col md={6} className="my-3 px-1">
          <h1 className={`mb-2`}>المجمع السكني</h1>
          <Select
            options={compoundOption}
            name="compound_id"
            value={selectedCompound}
            onChange={(selectedCom) => {
              setSelectedCompound(selectedCom);
              setFormData({ ...formData, compound_id: selectedCom.value });
            }}
          />
        </Col>
        <Col md={7} className="my-3 px-1">
          <Selected
            text="أنواع التشطيب"
            title="اختر أنواع التشطيب"
            name="types_of_finishing"
            value={formData?.types_of_finishing}
            onChange={handleChangeInput}
          >
            <option value="1">بدون تشطيب</option>
            <option value="2">نصف تشطيب</option>
            <option value="3">تشطيب كامل</option>
            <option value="4">مفروش</option>
          </Selected>
        </Col>
        <Col md={6} className="relative top-3 px-1">
          <AddImage
            id="images"
            name="image_location"
            newImage={viewImages?.image_location?.[0]}
            oldImage={
              dataOld?.image_location && fileUrl + dataOld?.image_location
            }
            onChange={handleChangeArrayImages}
            title="إضف صورة الموقع "
            clickDeleteImage={() => {
              setViewImages({
                ...viewImages,
                image_location: "",
              });
              setImages({
                ...images,
                image_location: "",
              });
            }}
          />
        </Col>
        <Col md={6} className="relative top-3 px-1">
          <AddImage
            id="images2"
            name="image_floor_plan"
            newImage={viewImages?.image_floor_plan?.[0]}
            oldImage={
              dataOld?.image_floor_plan && fileUrl + dataOld?.image_floor_plan
            }
            onChange={handleChangeArrayImages}
            title="إضف صورة مخطط الطابق"
            clickDeleteImage={() => {
              setViewImages({
                ...viewImages,
                image_floor_plan: "",
              });
              setImages({
                ...images,
                image_floor_plan: "",
              });
            }}
          />
        </Col>
        <Row>
          <Col className="mx-auto relative top-3 px-1">
            <AddImage
              accept="video image"
              title="أضف مجموعة صور"
              type="files"
              name="files[]"
              onChange={handleChangeArrayImages}
            />
            <Row className="py-4">
              {viewImages["files[]"] &&
                viewImages["files[]"]?.map((e, i) => (
                  <Col md={3} xs={4} sm={6} lg={24}>
                    <div className="relative">
                      <span
                        onClick={() => {
                          let total = [...viewImages["files[]"]];
                          let total2 = [...images["files[]"]];
                          total.splice(i, 1);
                          total2.splice(i, 1);
                          setViewImages({
                            ...viewImages["files[]"],
                            "files[]": total,
                          });
                          setImages({
                            ...images["files[]"],
                            "files[]": total2,
                          });
                        }}
                        className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                      >
                        X
                      </span>
                      <img src={e} alt="" className="w-full rounded-2xl" />
                    </div>
                  </Col>
                ))}
            </Row>
            {!AddId && (
              <div>
                <div
                  onClick={() => setDeleteImgs(!deleteImgs)}
                  className="cursor-pointer px-5 py-2 border border-Main w-fit rounded-lg text-lg text-white font-semibold bg-Main my-2 ml-auto "
                >
                  حذف الصور
                </div>
                <Row>
                  {dataOld?.images?.map((e) => (
                    <Col key={e.id} md={4} sm={6} className="relative">
                      {deleteImgs && (
                        <input
                          type="checkbox"
                          value={e.id}
                          name="images_to_delete[]"
                          onChange={handleCheckedArray}
                          className="absolute -top-2 left-1 h-5 w-5"
                        />
                      )}
                      <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
                        <img
                          src={fileUrl + e.file}
                          alt=""
                          className="w-full h-full object-contain rounded-2xl"
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </Col>
        </Row>
        <Col col={6}>
          <CheckInput
            name="trending"
            checked={formData.trending}
            onChange={handleChangeInput}
            title="جعل العقار رائج"
          />
        </Col>
        <Col col={6}>
          <CheckInput
            name="is_available"
            checked={formData.is_available}
            onChange={handleChangeInput}
            title="جعل العقار متاح"
          />
        </Col>
        {loading || (!AddId && isLoading) ? <Loading /> : ""}
        <div className="text-red-500 font-semibold">{error}</div>
        <Col col={6}>
          <ButtonRed
            onClick={handleSubmitMain}
            name=" حفظ"
            className="h-full px-4 py-3"
          />
        </Col>
      </Row>
      <Back />
    </Container>
  );
};

export default AddProperties;
