import { useLocation } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { ActiveFilter, PopupTable, Table, Title } from "../../components";

function Seller() {
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(
    `sellers${search}`,
    `sellers`
  );
  console.log(data?.data.data.data);
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      name: e.name,
      phone_number: e.phone_number,
      address: e.address,
      description: <PopupTable description="الوصف" text={e?.description} />,
      location: e.location,
      status: e.status,
    };
  });
  return (
    <div>
      <Title title="طلبات البيع" />
      <ActiveFilter />
      <Table
        isLoading={isLoading}
        thead={["اسم", "رقم الهاتف", "العنوان", "الوصف", "الموقع"]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={dataAll}
        tbody={["name", "phone_number", "address", "description", "location"]}
        funDelete={deleteItem}
        deleteText="حذف الطلب"
        status={true}
      />
    </div>
  );
}
export default Seller;
