import { BiShow } from "react-icons/bi";
import { Link } from "react-router-dom";
import IconHover from "../IconHover/IconHover";
import { FaBuilding } from "react-icons/fa";

function Show({ show, text, build }) {
  return (
    <>
      <IconHover id="show" text={text || "عرض"}>
        <div
          className={`w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer hover:bg-slate-200`}
        >
          <Link to={show || ""}>
            {build ? (
              <FaBuilding size={25} className="cursor-pointer text-gray-700" />
            ) : (
              <BiShow size={30} className="cursor-pointer text-blue-600" />
            )}
          </Link>
        </div>
      </IconHover>
    </>
  );
}
export default Show;
