import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import { CardBox, Data, Loading, Title } from "../../components";
const Orders = () => {
  const { data, isLoading, deleteItem } = useFETCH(
    `properties/orders`,
    "properties"
  );
  return (
    <>
      <Title title="العقارات" />
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data.data?.total / data?.data.data.per_page)}
      >
        <Row>
          {data?.data.data.data?.map((e) => (
            <Col md={6} key={e.id}>
              <CardBox
                deleteClick={() => deleteItem(e)}
                edit={`/orders/${e.id}`}
                show={`/orders/view/${e.id}`}
                accept={e.is_available}
                id={e.id}
                rout="properties"
              >
                <img
                  src={fileUrl + e.images?.[0]?.file}
                  alt=""
                  className="w-full h-[200px] object-contain rounded-2xl p-2"
                />
                <Data name="الاسم بالإنكليزي" text={e.name?.en} />
                <Data name="الاسم بالعربي" text={e.name?.ar} />
                <Data name="الموقع بالإنكليزي" text={e.address?.en} />
                <Data name="الموقع بالعربي" text={e.address?.ar} />
                <Data name="الوصف بالإنكليزي" text={e.description?.en} />
                <Data name="الوصف بالعربي" text={e.description?.ar} />
                <Data
                  name="عمليات الدفع بالإنكليزي"
                  text={e.payment_plans?.en}
                />
                <Data name="عمليات الدفع بالعربي" text={e.payment_plans?.ar} />
                <Data name="رقم الهاتف" text={e.phone_number} />
                <Data name="رقم الواتس أب" text={e.whatsapp} />
              </CardBox>
            </Col>
          ))}
        </Row>
      </Pagination>
    </>
  );
};

export default Orders;
