import parse from "html-react-parser";

function Data({ name, text, href, className, load }) {
  return (
    <div className={` mt-3 ${load ? "flex items-center" : ""}`}>
      <span className={`font-bold text-Main mx-1 ${className}`}>{name} :</span>
      {load ? (
        <div className="bg-slate-300 animate-pulse w-3/5 mx-1 rounded-sm h-5"></div>
      ) : (
        <span className="flex-1">{text && parse(text || "لا يوجد")}</span>
      )}
      {href && (
        <a
          href={href}
          target="_blank"
          className="text-black font-normal underline"
        >
          {href}
        </a>
      )}
    </div>
  );
}
export default Data;
