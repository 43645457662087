import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import {
  AddImage,
  Back,
  ButtonRed,
  HTMLInput,
  Input,
  Loading,
  TextArea,
  Title,
} from "../../components";

const EditBlogs = () => {
  const { id } = useParams();
  let AddId = id === "add";
  const [deleteImgs, setDeleteImgs] = useState();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    handleChangeArrayImages,
    setViewImages,
    handleCheckedArray,
    setImages,
    viewImages,
    images,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data, isLoading } = useFETCH(AddId ? "" : `blogs/${id}?local=none`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(AddId ? "blogs" : `blogs/${id}`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    !AddId &&
      setFormData({
        en_name: dataOld?.name?.en,
        ar_name: dataOld?.name?.ar,
        ar_small_text: dataOld?.small_text?.ar,
        en_small_text: dataOld?.small_text?.en,
        ar_large_text: dataOld?.large_text?.ar,
        en_large_text: dataOld?.large_text?.en,
      });
  }, [dataOld]);
  return (
    <div>
      <Row justify={"center"}>
        <Title title={AddId ? "أضف منتدى" : "تعديل منتدى"} />
        <Col lg={9}>
          <Row
            justify="center"
            className="border-2 border-Secondary p-8 rounded-xl"
          >
            <Input
              name="ar_name"
              value={formData?.ar_name}
              onChange={handleChangeInput}
              title="اسم المنتدى بالعربي"
            />
            <Input
              name="en_name"
              value={formData?.en_name}
              onChange={handleChangeInput}
              title="اسم المنتدى بالإنكليزي"
            />
            <TextArea
              name="ar_small_text"
              value={formData?.ar_small_text}
              onChange={handleChangeInput}
              title="النص الصغير بالعربي"
            />
            <TextArea
              name="en_small_text"
              value={formData?.en_small_text}
              onChange={handleChangeInput}
              title="النص الصغير بالإنكليزي"
            />
            <HTMLInput
              title="النص الطويل بالعربي"
              value={formData?.ar_large_text}
              onChange={(e) => setFormData({ ...formData, ar_large_text: e })}
            />
            <HTMLInput
              title="النص الطويل بالإنكليزي"
              value={formData?.en_large_text}
              onChange={(e) => setFormData({ ...formData, en_large_text: e })}
            />
            <Col className="mx-auto">
              <AddImage
                type="IMAGES"
                name="images[]"
                onChange={handleChangeArrayImages}
              />
              <Row className="py-4">
                {viewImages["images[]"] &&
                  viewImages["images[]"]?.map((e, i) => (
                    <Col md={3} xs={4} sm={6} lg={24}>
                      <div className="relative">
                        <span
                          onClick={() => {
                            let total = [...viewImages["images[]"]];
                            let total2 = [...images["images[]"]];
                            total.splice(i, 1);
                            total2.splice(i, 1);
                            setViewImages({
                              ...viewImages["images[]"],
                              "images[]": total,
                            });
                            setImages({
                              ...images["images[]"],
                              "images[]": total2,
                            });
                          }}
                          className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                        >
                          X
                        </span>
                        <img src={e} alt="" className="w-full rounded-2xl" />
                      </div>
                    </Col>
                  ))}
              </Row>
              {id !== "add" && (
                <div>
                  <div
                    onClick={() => setDeleteImgs(!deleteImgs)}
                    className="cursor-pointer px-5 py-2 border border-Main w-fit rounded-lg text-lg text-white font-semibold bg-Main my-2 ml-auto "
                  >
                    حذف الصور
                  </div>
                  <Row>
                    {dataOld?.images?.map((e) => (
                      <Col key={e.id} md={4} sm={6} className="relative">
                        {deleteImgs && (
                          <input
                            type="checkbox"
                            value={e.id}
                            name="images_to_delete[]"
                            onChange={handleCheckedArray}
                            className="absolute -top-2 left-1 h-5 w-5"
                          />
                        )}
                        <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
                          <img
                            src={fileUrl + e.image}
                            alt=""
                            className="w-full h-full object-contain rounded-2xl"
                          />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
            </Col>
            {loading || (!AddId && isLoading) ? <Loading /> : ""}
            <div className="text-red-500 font-semibold">{error}</div>
            <Col col={6}>
              <ButtonRed
                onClick={handleSubmitMain}
                name=" حفظ"
                className="h-full px-4 py-3"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Back />
    </div>
  );
};

export default EditBlogs;
