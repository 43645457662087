import { FaCheck } from "react-icons/fa";
import { MdBlock } from "react-icons/md";
import { useParams } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import { Add, CardBox, Data, Loading, SellerActive, Title } from "../../components";
const UsersProperties = () => {
  const { id } = useParams();
  const { data, isLoading, deleteItem } = useFETCH(
    `properties?user_id=${id}`,
    "properties"
  );
  const { data: dataUser } = useFETCH(`users/${id}`);
  let dataAll = dataUser?.data.data;
  return (
    <>
      <Col className="bg-white mx-auto border-2 border-Secondary py-5 px-3 rounded-xl">
        <Row className="justify-center">
          <Col md={6} className="flex items-center">
            <Data name="الاسم" text={dataAll?.name} />
          </Col>
          <Col md={6}>
            <Data name="الإيميل" text={dataAll?.email} />
          </Col>
          <Col md={6}>
            <Data name="العنوان" text={dataAll?.seller?.address} />
          </Col>
          <Col md={6}>
            <Data name="الموقع" text={dataAll?.seller?.location} />
          </Col>
          <Col md={6}>
            <Data name="رقم الهاتف" text={dataAll?.seller?.phone_number} />
          </Col>
          <Col md={6} className="flex items-center justify-between">
            <p
              className={`${
                dataAll?.seller?.is_active ? "text-green-600" : "text-red-600"
              } text-xl font-semibold`}
            >
              {dataAll?.seller?.is_active ? "فعال" : "غير فعال"}
            </p>
            {dataAll?.seller?.is_active === 0 ? (
              <SellerActive text="البائع" id={dataAll?.seller?.id} active="1">
                <FaCheck size={30} className=" text-green-700" />
              </SellerActive>
            ) : (
              <SellerActive
                text="البائع"
                id={dataAll?.seller?.id}
                active="0"
                name="إلغاء"
              >
                <MdBlock size={30} className=" text-red-700" />
              </SellerActive>
            )}
          </Col>
        </Row>
      </Col>
      <Title title="العقارات" />
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
      >
        <Row>
          {data?.data.data.data?.map((e) => (
            <Col md={6} key={e.id}>
              <CardBox
                deleteClick={() => deleteItem(e)}
                edit={`/properties/${e.id}`}
                show={`/properties/view/${e.id}`}
              >
                <img
                  src={fileUrl + e.images[0]?.file}
                  alt=""
                  className="w-full h-[200px] rounded-2xl p-2"
                />
                <Data name="الاسم بالإنكليزي" text={e.name?.en} />
                <Data name="الاسم بالعربي" text={e.name?.ar} />
                <Data name="الموقع بالإنكليزي" text={e.address?.en} />
                <Data name="الموقع بالعربي" text={e.address?.ar} />
                <Data name="الوصف بالعربي" text={e.description?.ar} />
                <Data name="الوصف بالإنكليزي" text={e.description?.en} />
                <Data
                  name="عمليات الدفع بالإنكليزي"
                  text={e.payment_plans?.en}
                />
                <Data name="عمليات الدفع بالعربي" text={e.payment_plans?.ar} />
                <Data name="رقم الهاتف" text={e.phone_number} />
                <Data name="رقم الواتس أب" text={e.whatsapp} />
              </CardBox>
            </Col>
          ))}
        </Row>
      </Pagination>

      <Add link={`/properties/add`} />
    </>
  );
};

export default UsersProperties;
