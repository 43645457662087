import { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { useLOGIN } from "../../Tools/APIs/useMyAPI";
import { Col } from "../../Tools/Grid-system";
import { ButtonRed, Loading } from "../../components";
import Logo from "../../images/Logo.png";

const Login = () => {
  const [biShow, setBiShow] = useState(false);
  const { handleSubmit, error, loading, handleChange } = useLOGIN();
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("login");
  };
  return (
    <div className="fixed w-full h-full bg-white top-0 left-0 z-50 flex items-center justify-center text-center">
      <div className="w-1/2 max-lg:w-[90%] flex flex-col justify-center space-y-6 border border-Main p-10 rounded-2xl">
        <div className="flex justify-center">
          <img src={Logo} alt="" className="w-[300px] mx-auto" />
        </div>
        <div className="flex w-full mx-3">
          <input
            type="email"
            name="email"
            onChange={handleChange}
            placeholder="الإيميل"
            className="block max-md:w-full mx-auto w-[100%] border-b border-b-Main py-3 pr-5 font-semibold outline-none px-5"
          />
        </div>
        <div className="flex w-full mx-3 relative">
          <input
            type={!biShow ? "password" : "text"}
            name="password"
            onChange={handleChange}
            placeholder="كلمة السر"
            className="block max-md:w-full mx-auto w-[100%] border-b border-b-Main py-3 pr-5 font-semibold outline-none px-5"
          />
          {biShow ? (
            <BiShow
              onClick={() => setBiShow(false)}
              size={25}
              className="absolute left-3 top-4 cursor-pointer"
            />
          ) : (
            <BiHide
              onClick={() => setBiShow(true)}
              size={25}
              className="absolute left-3 top-4 cursor-pointer"
            />
          )}
        </div>
        {/* <Link to="/forget-password">
          <p className="text-gray-400 text-center underline">
            Forget your password?
          </p>
        </Link> */}
        {loading ? <Loading /> : ""}
        <div className="text-red-600">{error}</div>
        <Col md={6} sm={9} className="mx-auto">
          <ButtonRed name="إرسال" className="py-3" onClick={handleSubmitMain} />
        </Col>
      </div>
    </div>
  );
};

export default Login;
