import { useLocation } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Add, Table, Title, TopCompoundFilter } from "../../components";
const Areas = () => {
  const { search } = useLocation({});
  const { data, isLoading, deleteItem } = useFETCH(`areas${search}`, "areas");
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      image: fileUrl + e.image,
      name_ar: e.name?.ar,
      name_en: e.name?.en,
      area_type: e.trending === 1 ? "رائجة" : "غير رائجة",
    };
  });
  return (
    <>
      <Title title="المناطق" />
      <TopCompoundFilter />
      <Table
        isLoading={isLoading}
        thead={[
          "الرقم",
          "صورة المنطقة",
          "اسم المنطقة بالعربي",
          "اسم المنطقة بالإنكليزي",
          "نوع المنطقة",
        ]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={dataAll}
        tbody={["id", "image", "name_ar", "name_en", "area_type"]}
        funDelete={deleteItem}
        edit="area"
      />
      <Add link="/area/add" />
    </>
  );
};

export default Areas;
