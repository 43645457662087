import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Add, PopupTable, Table, Title } from "../../components";
const Compounds = () => {
  const { data, isLoading, deleteItem } = useFETCH(`compounds`, "compounds");
  const dataAll = data?.data?.data?.data?.map((e) => {
    return {
      id: e.id,
      image: fileUrl + e.images[0]?.file,
      name_ar: e.name?.ar,
      description: <PopupTable description="الوصف" text={e.description?.ar} />,
      start_price: e.start_price,
      end_price: e.end_price,
      phone_number: e.phone_number,
      whatsapp: e.whatsapp,
    };
  });
  return (
    <>
      <Title title="المجمعات" />
      <Table
        isLoading={isLoading}
        thead={[
          "صورة المجمع",
          "اسم المحمع",
          "وصف المجمع",
          "السعر الأولي",
          "السعر النهائي",
          "رقم الهاتف",
          "رقم الواتس أب",
        ]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={dataAll}
        tbody={[
          "image",
          "name_ar",
          "description",
          "start_price",
          "end_price",
          "phone_number",
          "whatsapp",
        ]}
        funDelete={deleteItem}
        edit="compounds"
        show="compounds/show"
        showText="عرض تفاصيل مجمع"
      />
      <Add link={`/compounds/add`} />
    </>
  );
};

export default Compounds;
