import React from "react";

const Title = ({ title, className }) => {
  return (
    <div className="">
      <li
        className={`text-Main text-start mb-3 mr-3 text-xl font-bold ${className}`}
      >
        {title}
      </li>
    </div>
  );
};

export default Title;
