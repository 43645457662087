function TextArea({ title, name, value, onChange, className }) {
  return (
    <div className="my-3 px-1">
      <h1 className={`text-start mb-2 ${className}`}>{title}</h1>
      <textarea
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={title}
        className={`border border-[#cccccc] py-[6px] outline-none m-1 rounded-md block w-full px-3 h-[150px] ${className}`}
      />
    </div>
  );
}
export default TextArea;
